import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage} from 'formik';
import axios from 'axios';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

const CreateCategory = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const [showOptions, setShowOptions] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    }
  }, [navigate, token]);

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(`${backendUrl}/api/categories/admin`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        // Display success toast
        toast.success('Category created successfully!');
        // Handle successful category creation
        navigate('/admin/list-categories');
      }
    } catch (error) {
      console.error('Category Creation error:', error);
      // Display error toast
      toast.error('An error occurred while creating the category.');
    }
  };

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };
  
  const handleNavigation = (route) => {
    navigate(route);
  };
  

  // Define the validation schema for category
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Category name is required'),
    about: Yup.string().required('Category about is required')
  });

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-md-6">
          <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-moderators')}>
                  List Moderators
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-moderator')}>
                  Create Moderators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-8">
          <div className="card joblist-card">
            <div className="card-header text-center joblist-form-header">Create Category</div>
            <div className="card-body">
              <Formik
                initialValues={{
                  name: '',
                  about: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                >
                <Form>
                    <div className="form-group joblist-form">
                      <label htmlFor="name">Category Name</label>
                      <Field type="text" id="name" name="name" className="form-control joblist-input" />
                      <ErrorMessage name="name" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="about">About</label>
                      <Field type="text" id="about" name="about" className="form-control joblist-input" />
                      <ErrorMessage name="about" component="div" className="error text-danger" />
                    </div>
                    
                    <div className="form-group mt-4 text-center">
                      <button type="submit" className="btn btn-primary">
                        Create Listing
                      </button>
                    </div>
                  </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategory;