import React, { useEffect } from 'react';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './adminNav.css';

const EditorHome = () => {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const token = localStorage.getItem('token'); // Get the JWT token

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    }
  }, [navigate, token]);

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };

  const handleNavigation = (route) => {
    navigate(route);
  };

  // Render the protected content
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditorHome;
