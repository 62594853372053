import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom'

function Header() {
    return (
        <section id="header">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <img src="../../images/logo-main.jpeg"/>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="bi bi-list"></i>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/user/privacy">Privacy policy</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/user/about">About us</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/user/contact">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Header;