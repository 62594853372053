import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './cardcustom.css';
import './adminNav.css';
import { toast } from 'react-hot-toast';

const ListJobs = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const token = localStorage.getItem('token'); // Get the JWT token
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [totalJobs, setTotalJobs] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    } else {
      // Fetch the list of jobs from the server when the component mounts
      axios.get(`${backendUrl}/api/jobs`).then((response) => {
        setJobs(response.data); // Assuming the response data is an array of job objects
      });

      // Fetch the total number of jobs
      axios.get(`${backendUrl}/api/jobs/count`).then((response) => {
        const count = response.data.totalJobs;
        setTotalJobs(count);
      });
    }
  }, [navigate, token, backendUrl]);

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };

  const handleUpdate = (jobId) => {
    // Navigate to the UpdateJob component with the jobId as a parameter
    navigate(`/admin/update-job/${jobId}`, { replace: true });
  };

  const handleView = (jobId) => {
    // Navigate to the ViewJob component with the jobId as a parameter
    navigate(`/admin/view-job/${jobId}`);
  };

  const handleDelete = (jobId) => {
    // Ask for confirmation before deleting
    const confirmDelete = window.confirm('Are you sure you want to delete this job?');
    if (confirmDelete) {
      // Send a DELETE request to the server to delete the job
      axios
        .delete(`${backendUrl}/api/jobs/${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          // Remove the deleted job from the local state
          setJobs((prevJobs) => prevJobs.filter((job) => job._id !== jobId));
          // Display success toast
          toast.success('Job deleted successfully');
        })
        .catch((error) => {
          console.error('Job deletion error:', error);
          // Display error toast
          toast.error('An error occurred while deleting the job');
        });
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredJobs = jobs.filter((job) =>
    job.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-4">
        <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>

              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec">
                  <a href="/" onClick={(e) => { e.preventDefault(); handleNavigation('/'); }}>
                    Home
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/list-jobs" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/list-jobs'); }}>
                    List Jobs
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/list-jobs-staff" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/list-jobs-staff'); }}>
                    Staff Jobs
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/create-job" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/create-job'); }}>
                    Create Job
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/list-categories" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/list-categories'); }}>
                    List Categories
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/create-category" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/create-category'); }}>
                    Create Category
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/list-subCategories" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/list-subCategories'); }}>
                    List SubCategories
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/create-subCategory" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/create-subCategory'); }}>
                    Create SubCategory
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/list-moderators" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/list-moderators'); }}>
                    List Moderators
                  </a>
                </li>
                <li className="nav-option-sec">
                  <a href="/admin/create-moderator" onClick={(e) => { e.preventDefault(); handleNavigation('/admin/create-moderator'); }}>
                    Create Moderators
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search by Job Title"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control text-center search-joblist d-inline"
            />
          </div>
        </div>
        <div className="col-md-4 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>

      <div className="job-cards mt-5">
        {totalJobs !== null && (
          <div className="total-jobs-count">Total Jobs = {totalJobs}</div>
        )}
        <div className="row">
          {filteredJobs.map((job) => (
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4" key={job._id}>
              <div className="card card-joblist">
                <div className="card-header text-center card-header-joblist"><strong>{job.title}</strong></div>
                <div className="card-body">
                  <p className="card-title">Company: {job.companyName}</p>
                  <p className="card-text">Location: {job.location}</p>
                  <p className="card-text">Category: {job.jobCategory}</p>
                  <div className="job-actions text-center">
                    <button onClick={() => handleView(job._id)} className="btn btn-success me-2">
                      View
                    </button>
                    <button onClick={() => handleUpdate(job._id)} className="btn btn-primary me-2">
                      Update
                    </button>
                    <button onClick={() => handleDelete(job._id)} className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListJobs;
