import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './adminNav.css';
import axios from 'axios';
import '../../ckeditor.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ViewJob = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const [showOptions, setShowOptions] = useState(false);
  const token = localStorage.getItem('token'); // Get the JWT token

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    }
  }, [navigate, token]);

  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    // Fetch the job details based on the jobId
    axios
      .get(`${backendUrl}/api/jobs/admin/${jobId}`)
      .then((response) => {
        setJobDetails(response.data);
      })
      .catch((error) => {
        console.error('Job details fetch error:', error);
        // Handle error
      });
  }, [jobId, backendUrl]);

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <>
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-moderators')}>
                  List Moderators
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-moderator')}>
                  Create Moderators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>
    </div>
    <Container className="mt-5">
        <Row>
          <Col md={12} className="mx-auto">
            <Card>
              <Card.Header className="text-center">
                <strong>Job Details</strong>
              </Card.Header>
              <Card.Body>
                {jobDetails ? (
                  <div>
                    <h2>Title: {jobDetails.title}</h2>
                    <p>Company Name: {jobDetails.companyName}</p>
                    <p>Job Location: {jobDetails.location}</p>
                    <p>Category: {jobDetails.jobCategory}</p>
                    <p>Experience: {jobDetails.experience}</p>
                    <p>Link: {jobDetails.extra}</p>
                    <p>Qualification: {jobDetails.qualification}</p>
                    <p>Salary: {jobDetails.salary}</p>
                    <p>Role: {jobDetails.role}</p>
                    <p>RefID: {jobDetails.RefID}</p>
                    <p>Created By: {jobDetails.createdBy}</p>
                    <div>
        {jobDetails.subCategories.map((subcategory, index) => (
          <p key={index}>SubCategory: {subcategory}</p>
        ))}
      </div>
                    <p>Posts: {jobDetails.posts}</p>
                    <p>Job Opening Date: {jobDetails.jobOpeningDate}</p>
                    <p>Job Closing Date: {jobDetails.jobClosingDate}</p>
                    <div className='ck-content' dangerouslySetInnerHTML={{ __html: jobDetails.aboutJob }} />
                  </div>
                ) : (
                  <p>Loading...</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewJob;
