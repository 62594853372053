import { Link } from "react-router-dom";
import Header from "./Header";

function Contact() {
    return (
        <>
        <Header/>
        <section id="contact">
        {/* banner Start */}
        <div className="container">
                    <h1>Contact Us</h1>
                    <div id="banner">
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                        <h4>Always test your software with a "worst-case scenario"</h4>
                        <p id="call"><Link to={'#'}><button>Dial a Call</button></Link></p>
                </div>
                        </div>
                <div className="col-md-3"></div>
            </div>
        </div>
        {/* banner end */}

        {/* information start */}
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div id="meeting">
                    <h3>Meet Us</h3>
                    <p><i class="bi bi-telephone me-2"></i> <Link to={"#"}>+91 01592 55782</Link></p>
                    <p><i class="bi bi-envelope-at me-2"></i> <Link to={"#"}>bhoomiplus@gmail.com</Link></p>
                    <p><i class="bi bi-geo-alt me-2"></i> <span> Jhunjhunu, Rajasthan</span></p>
                    </div>
                </div>
            </div>
        </div>
        {/* information end */}



        {/* Social Link Start */}

        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div id="social">
                    <Link to={'#'}><i class="bi bi-twitter"></i></Link>
                    <Link to={'#'}><i class="bi bi-facebook"></i></Link>
                    <Link to={'#'}><i class="bi bi-linkedin"></i></Link>
                    </div>
                </div>
            </div>
        </div>

        {/* Social Link End */}
        </section>
        </> 
     );
}

export default Contact;