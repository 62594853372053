import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ListJobsByCategory = () => {
  const { jobCategory } = useParams();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    // Define your backend URL
    const backendUrl = process.env.REACT_APP_SERVER_URL;

    axios.get(`${backendUrl}/api/jobs/all-jobs/${jobCategory}`)
      .then((response) => {
        setJobs(response.data);
      })
      .catch((error) => {
        console.error('Error fetching job listings:', error);
      });
  }, [jobCategory]);

  return (
    <div>
      <h1>Jobs in Category: {jobCategory}</h1>
      <ul>
        {jobs.map((job) => (
          <li key={job._id}>{job.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default ListJobsByCategory;