import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CardCustomUser.css';

const Hero = () => {
  const navigate = useNavigate();

  const handleDivClick = () => {
    navigate('/');
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-6 d-flex align-items-center">
          <div className="admin-navigation" onClick={handleDivClick}>
            <img className="logo-img" src="../images/logo-main.jpeg" alt="Logo" />
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-dark btn-sm mx-2"
            onClick={handleDivClick}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
