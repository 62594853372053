import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage} from 'formik';
import Select from 'react-select';
import axios from 'axios';
import './adminNav.css';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';

const CreateJobListing = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const [showOptions, setShowOptions] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    }
  }, [navigate, token]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  // Modify the selectedSubCategoriesObjects creation to extract values
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const userName = localStorage.getItem('username');

  useEffect(() => {
    // Fetch categories
    axios.get(`${backendUrl}/api/categories`).then((response) => {
      const categories = response.data.map((category) => ({
        label: category.name,
        value: category._id,
      }));
      setCategoryOptions(categories);
    });

    // Fetch subcategories
    axios.get(`${backendUrl}/api/subCategories`).then((response) => {
      const subCategories = response.data.map((subCategory) => ({
        label: subCategory.name,
        value: subCategory.name,
      }));
      setSelectedSubCategories(subCategories);
    });
  }, [backendUrl]);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      // Fetch the category name based on the selected category ID
      const selectedCategory = categoryOptions.find((option) => option.value === values.jobCategory);
  
      // Map the selected subCategories to their IDs
      const selectedSubCategoryIds = selectedSubCategories
        .filter((subCategory) => values.subCategories.includes(subCategory.value))
        .map((subCategory) => subCategory.value);
  
      const response = await axios.post(
        `${backendUrl}/api/jobs/admin`,
        {
          ...values,
          jobCategory: selectedCategory ? selectedCategory.label : '',
          createdBy: userName,
          subCategories: selectedSubCategoryIds, // Use the IDs of selected subCategories
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 201) {
        // Display success toast
        toast.success('Job listing created successfully!');
        // Reset the form to its initial state
        resetForm();
        // Handle successful job creation, e.g., redirect to a confirmation page
        navigate('/admin/list-jobs');
      }
    } catch (error) {
      console.error('Job creation error:', error);
      // Display more specific error messages based on the error response
      if (error.response) {
        // You can inspect the error.response object to provide detailed error messages
        // Example: toast.error(`Error: ${error.response.data.message}`);
      } else {
        // Handle other types of errors, such as network issues
        toast.error('An error occurred while creating the job listing.');
      }
    } finally {
      // Set submitting to false to enable the form submit button again
      setSubmitting(false);
    }
  };
  
  
  //function to generate seo url
  const generateSeoUrl = (category, title) => {
    if (category && title) {
      const categoryName = category.label.toLowerCase().replace(/\s+/g, '-');
      const jobTitle = title.toLowerCase().replace(/\s+/g, '-');
      return `${categoryName}_${jobTitle}`;
    }
    return '';
  };

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };
  
  const handleNavigation = (route) => {
    navigate(route);
  };

  // Define the validation schema for the form
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Job Title is required'),
    companyLogo: Yup.string().required('Company Logo URL is required'),
    companyName: Yup.string().required('Company Name is required'),
    qualification: Yup.string().required('Qualification is required'),
    location: Yup.string().required('Location is required'),
    experience: Yup.string().required('Experience is required'),
    salary: Yup.string().required('Salary is required'),
    role: Yup.string().required('Role is required'),
    jobCategory: Yup.string().required('Job Category is required'),
    seoUrl: Yup.string().required('SEO Url is required'),
    extra: Yup.string().required('this is required'),
    aboutJob: Yup.string()
      .required('Job About is required')
      .test('word-count', 'About Job must have more than 100 words', (value) => {
        if (value) {
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount > 100;
        }
        return false;
      }),
  });

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-md-6">
          <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-moderators')}>
                  List Moderators
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-moderator')}>
                  Create Moderators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-12">
          <div className="card joblist-card">
            <div className="card-header text-center joblist-form-header">Create Job Listing</div>
            <div className="card-body">
              <Formik
                initialValues={{
                  title: '',
                  companyLogo: '',
                  companyName: '',
                  qualification: '',
                  location: '',
                  experience: '',
                  salary: '',
                  role: '',
                  jobCategory: '',
                  subCategories: [],
                  createdBy: userName,
                  seoUrl: '',
                  extra: '',
                  posts: '',
                  jobOpeningDate: '',
                  jobClosingDate: '',
                  RefID: '',
                  aboutJob: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                >
                {({ values, setFieldValue }) => ( // Add values, handleChange, and setFieldValue
                <Form>
                    <div className="form-group joblist-form">
                      <label htmlFor="title">Job Title</label>
                      <Field
                        type="text"
                        id="title"
                        name="title"
                        className="form-control joblist-input"
                        // Generate and set the SEO URL based on category and title
                        onChange={(e) => {
                          setFieldValue('title', e.target.value);
                          const seoUrl = generateSeoUrl(values.jobCategory, e.target.value);
                          setFieldValue('seoUrl', seoUrl);
                        }}
                      />
                      <ErrorMessage name="title" component="div" className="error text-danger" />
                    </div>
                    <div className="form-group joblist-form">
                      <label htmlFor="companyLogo">Company Logo</label>
                      <Field type="text" id="companyLogo" name="companyLogo" className="form-control joblist-input" />
                      <ErrorMessage name="companyLogo" component="div" className="error text-danger" />
                    </div>
                    <div className="form-group joblist-form">
                      <label htmlFor="companyName">Company Name</label>
                      <Field type="text" id="companyName" name="companyName" className="form-control joblist-input" />
                      <ErrorMessage name="companyName" component="div" className="error text-danger" />
                    </div>
                    <div className="form-group joblist-form">
                      <label htmlFor="qualification">Qualification</label>
                      <Field type="text" id="qualification" name="qualification" className="form-control joblist-input" />
                      <ErrorMessage name="qualification" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="location">Location</label>
                      <Field type="text" id="location" name="location" className="form-control joblist-input" />
                      <ErrorMessage name="location" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="experience">Experience</label>
                      <Field type="text" id="experience" name="experience" className="form-control joblist-input" />
                      <ErrorMessage name="experience" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="extra">Link</label>
                      <Field type="text" id="extra" name="extra" className="form-control joblist-input" />
                      <ErrorMessage name="extra" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="salary">Salary</label>
                      <Field type="text" id="salary" name="salary" className="form-control joblist-input" />
                      <ErrorMessage name="salary" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="role">Role</label>
                      <Field type="text" id="role" name="role" className="form-control joblist-input" />
                      <ErrorMessage name="role" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="jobCategory">Job Category</label>
                      <Select
                        id="jobCategory"
                        className="joblist-input"
                        name="jobCategory"
                        options={categoryOptions}
                        onChange={(selectedOption) => {
                          setFieldValue('jobCategory', selectedOption ? selectedOption.value : '');
                          // Generate and set the SEO URL based on category and title
                          const seoUrl = generateSeoUrl(selectedOption, values.title);
                          setFieldValue('seoUrl', seoUrl);
                        }}
                        value={categoryOptions.find((option) => option.value === values.jobCategory)}
                      />
                      <ErrorMessage name="jobCategory" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="subCategories">Subcategories</label>
                      <Select
                        id="subCategories"
                        className="joblist-input"
                        name="subCategories"
                        options={selectedSubCategories}
                        isMulti={true} // Allow multiple selection
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          setFieldValue('subCategories', selectedValues);
                        }}
                        value={selectedSubCategories.filter((option) =>
                          values.subCategories.includes(option.value)
                        )}
                      />
                      <ErrorMessage name="subCategories" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="createdBy">Created By</label>
                      <Field
                        type="text"
                        id="createdBy"
                        name="createdBy"
                        className="form-control joblist-input"
                        readOnly={true} // Make the field read-only
                      />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="RefID">Ref ID</label>
                      <Field type="text" id="RefID" name="RefID" className="form-control joblist-input" />
                    </div>

                    {/* SEO URL Field */}
                    <div className="form-group joblist-form">
                      <label htmlFor="seoUrl">SEO URL</label>
                      <Field type="text" id="seoUrl" name="seoUrl" className="form-control joblist-input" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="posts">Posts</label>
                      <Field type="text" id="posts" name="posts" className="form-control joblist-input" />
                      <ErrorMessage name="text" component="div" className="error text-danger" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="jobOpeningDate">Job Opening Date</label>
                      <Field type="text" id="jobOpeningDate" name="jobOpeningDate" className="form-control joblist-input" />
                    </div>

                    <div className="form-group joblist-form">
                      <label htmlFor="jobClosingDate">Job Closing Date</label>
                      <Field type="text" id="jobClosingDate" name="jobClosingDate" className="form-control joblist-input" />
                    </div>

                    <div className="form-group joblist-form">
<label htmlFor="aboutJob">About Job</label>
<Editor // Use Editor from TinyMCE
apiKey="kfhdkybzno55pw816tl6zx44w1i6hqdt2rw3673lprn73qs9" // Replace with your TinyMCE API Key
initialValue="<p>TinyMCE React demo</p>"
                        init={{
                          height: 300,
                          menubar: 'favs file edit view insert format tools table help',
                          plugins: [
                            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                            'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                            'table', 'emoticons', 'help'
                          ],
                          toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                            'forecolor backcolor emoticons | help',
                          menu: {
                            favs: { title: 'Others', items: 'code visualaid | searchreplace | emoticons' }
                          },
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('aboutJob', content);
                        }}
                      />
<ErrorMessage name="aboutJob" component="div" className="error text-danger" />
</div>

                    <div className="form-group mt-4 text-center">
                      <button type="submit" className="btn btn-primary">
                        Create Listing
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateJobListing;
