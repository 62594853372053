import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './ViewPage.css';
import axios from 'axios';
import Footer from './Footer';
import Hero from './Hero';
import '../admin/custom-quill.css';
import {BiBuilding, BiCategory, BiCurrentLocation, BiMoney, BiSolidRocket, BiSolidSchool, BiSolidUserCircle, BiChart} from "react-icons/bi";
import {BsFillCalendar2PlusFill, BsFillCalendar2XFill, BsFillCloudArrowUpFill, BsLink} from "react-icons/bs";
import {TbListDetails} from 'react-icons/tb';
import { HashLoader } from 'react-spinners';
import '../../ckeditor.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ViewJobUser = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;

  const { seoUrl } = useParams(); // Get the seoUrl from URL parameters
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the job details based on the seoUrl
    axios
      .get(`${backendUrl}/api/jobs/view/${seoUrl}`)
      .then((response) => {
        setJobDetails(response.data);
        console.log(response.data);
        setLoading(false); // Set loading to false when job details are fetched
      })
      .catch((error) => {
        console.error('Job details fetch error:', error);
        setLoading(false); // Set loading to false on error as well
        // Handle error
      });
  }, [seoUrl, backendUrl]);

  const handleViewMore = () => {
    // Check if jobDetails and extra property exist before opening the link
    if (jobDetails && jobDetails.extra) {
      // Open the link in a new tab
      window.open(jobDetails.extra, '_blank');
    }
  };

  return (
    <>
      <Hero />
      <Container className="mt-5">
        <Row>
          <Col md={12} className="mx-auto">
            <Card className="view-page-card">
            <Card.Body>
                {loading ? ( // Display loader if loading is true
                  <div className="loader-container text-center">
                    <HashLoader color="#198754" loading={loading} size={50} /> {/* Use HashLoader here */}
                  </div>
                ) : (
                  jobDetails ? (
                    <div>
                    <Card.Header className="text-center view-page-header">
                      <strong>{jobDetails.title}</strong>
                    </Card.Header>
                    <div className='text-center mb-img-div'>
                        <img src={jobDetails.companyLogo} alt='img'></img>
                    </div>
                    <div className='row container about-items'>
                      <div className='col col-md-8'>
                        <p><BiBuilding /> <strong>Company Name:</strong> {jobDetails.companyName}</p>
                        <p><BiCurrentLocation /> <strong>Job Location:</strong> {jobDetails.location}</p>
                        <p><BiCategory /> <strong>Category:</strong> {jobDetails.jobCategory}</p>
                        <p><BiSolidSchool /> <strong>Qualification:</strong> {jobDetails.qualification}</p>
                        <p><BiSolidRocket /> <strong>Experience:</strong> {jobDetails.experience}</p>
                        <p><BiMoney /> <strong>Salary:</strong> {jobDetails.salary}</p>
                        <p hidden><BsFillCloudArrowUpFill /> <strong hidden>JobID:</strong> {jobDetails.RefID}</p>
                        <p><BiSolidUserCircle /> <strong>Role:</strong> {jobDetails.role}</p>
                        <p hidden><BiChart /> <strong hidden>Posts:</strong> {jobDetails.posts}</p>
                        <p><BsFillCalendar2PlusFill /> <strong>Job Publishing Date:</strong> {jobDetails.jobOpeningDate.split('T')[0]}</p>
                        <p><BsFillCalendar2XFill /> <strong>Last Date to Apply:</strong> {jobDetails.jobClosingDate.split('T')[0]}</p>
                        
                      </div>
                      <div className='col col-md-4 about-img-div'>
                        <img src={jobDetails.companyLogo} alt='img'></img>
                      </div>
                    </div>
                    <p className='text-center about-heading'><TbListDetails /><strong>About Job</strong></p>
            
                    <div className='ck-content' dangerouslySetInnerHTML={{ __html: jobDetails.aboutJob }} />
                    <div className="text-center mt-3">
                      <button className="btn btn-primary" onClick={handleViewMore}>
                        Apply Now
                      </button>
                    </div>
                  </div>
                  ) : (
                    <p>No job details found.</p>
                  )
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ViewJobUser;
