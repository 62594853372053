import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './adminNav.css';
import './CreateJobListing.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

const CategoryUpdate = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const [showOptions, setShowOptions] = useState(false);
  const token = localStorage.getItem('token');
  const { categoryId } = useParams(); // Get the categoryId from the route parameter

  const [categoryDetails, setCategoryDetails] = useState({
    name: '',
    about: '',
  });

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    } else {
      // Fetch the category details based on categoryId
      axios
        .get(`${backendUrl}/api/categories/admin/${categoryId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setCategoryDetails(response.data);
        })
        .catch((error) => {
          console.error('Category details fetch error:', error);
          // Handle error
        });
    }
  }, [navigate, token, categoryId, backendUrl]);

  // Handle form submission
  const handleSubmit = async (values) => {
    try {
      // Send a PUT request to update the category listing
      const response = await axios.put(
        `${backendUrl}/api/categories/admin/${categoryId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Display success toast
        toast.success('Category listing updated successfully!');
        // Redirect to the category list page or any other appropriate page
        navigate('/admin/list-categories');
      }
    } catch (error) {
      console.error('Category update error:', error);
      // Display error toast
      toast.error('An error occurred while updating the category listing.');
    }
  };

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };
  
  const handleNavigation = (route) => {
    navigate(route);
  };

  // Define the validation schema for the form
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Category Name is required'),
    about: Yup.string().required('Category Description is required'),
  });

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-md-6">
          <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-moderators')}>
                  List Moderators
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-moderator')}>
                  Create Moderators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-md-12">
          <div className="card joblist-card">
            <div className="card-header text-center joblist-form-header">
              <strong>Update Category Listing</strong>
            </div>
            <div className="card-body">
              {categoryDetails.name ? ( // Check if categoryDetails has been fetched
                <Formik
                  initialValues={{
                    ...categoryDetails,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Form>
                      <div className="form-group joblisting-form">
                        <label htmlFor="name">Category Name</label>
                        <Field type="text" id="name" name="name" className="form-control joblisting-input" />
                        <ErrorMessage name="name" component="div" className="error text-danger" />
                      </div>

                      <div className="form-group joblisting-form">
                        <label htmlFor="about">Category Description</label>
                        <Field as="textarea" id="about" name="about" className="form-control joblisting-input" />
                        <ErrorMessage name="about" component="div" className="error text-danger" />
                      </div>

                      <div className="form-group mt-4 text-center">
                        <button type="submit" className="btn btn-primary">
                          Update Listing
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <p>Loading...</p> // Display loading message while data is being fetched
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryUpdate;
