import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div>
        <footer>
        <div class="container">
            <div class="row col-lg-12 col-md-12">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-box">
                        <img src="../images/logo-main.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
  )
}

export default Footer
