import Header from "./Header";

function About() {
    return ( 
        <>
        <Header/>
        <section id="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>About Information</h2>
                        <h4>Welcome To News4jobs</h4>
                        <p>News4jobs is a Professional jobs Platform. Here we will only provide you with interesting content that you will enjoy very much. We are committed to providing you the best of jobs, with a focus on reliability and tutorial. we strive to turn our passion for jobs into a thriving website. We hope you enjoy our jobs as much as we enjoy giving them to you.</p>
                        <p>I will keep on posting such valuable anf knowledgeable information on my Website for all of you. Your love and support matters a lot.</p>
                        <h6>Thank you For Visiting Our Site</h6>
                        <h6>Have a great day !</h6>
                    </div>
                </div>
            </div>
        </section>
        </>
     );
}

export default About;