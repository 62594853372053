import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './CreateJobListing.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Select from 'react-select';
import toast from 'react-hot-toast';
import './adminNav.css';
import './CreateJobListing.css'
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';

const JobUpdate = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const [showOptions, setShowOptions] = useState(false);
  const token = localStorage.getItem('token');
  const userName = localStorage.getItem('username');
  const { jobId } = useParams(); // Get the jobId from the route parameter

  const [jobDetails, setJobDetails] = useState({
    title: '',
    companyLogo: '',
    companyName: '',
    qualification: '',
    location: '',
    experience: '',
    extra: '',
    salary: '',
    role: '',
    jobCategory: '', // Store category name instead of ID
    subCategories: [],
    createdBy: userName,
    posts: '',
    RefID: '',
    jobOpeningDate: '',
    jobClosingDate: '',
    aboutJob: '',
  });

  const [defaultCategory, setDefaultCategory] = useState('');
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [aboutJobContent, setAboutJobContent] = useState('');

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    } else {
      // Fetch the job details based on jobId
      axios
        .get(`${backendUrl}/api/jobs/admin/${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {

          setDefaultCategory(response.data.jobCategory);
          setSelectedSubCategories(response.data.subCategories);
          setAboutJobContent(response.data.aboutJob);
          const openingDate = response.data.jobOpeningDate;
          const closingDate = response.data.jobClosingDate;
          setJobDetails({
            ...response.data,
            jobOpeningDate: openingDate,
            jobClosingDate: closingDate,
            jobCategory: response.data.jobCategory.name,
            subCategories: response.data.subCategories.map((subCategory) => subCategory.name),
            createdBy: response.data.createdBy,
            jobId: response.data.RefID,
            extra: response.data.extra,
          });

        })
        .catch((error) => {
          console.error('Job details fetch error:', error);
          // Handle error
        });
    }
  }, [navigate, token, jobId, backendUrl]);

  useEffect(() => {
    // Fetch the subCategories
    axios.get(`${backendUrl}/api/subcategories`).then((response) => {
      const subCategories = response.data.map((subCategory) => ({
        label: subCategory.name,
        value: subCategory._id,
      }));
      setSubCategoryOptions(subCategories);
    });
  }, [backendUrl]);

  // Handle form submission
  const handleSubmit = async (values) => {
    try {
      values.jobOpeningDate = values.jobOpeningDate.toString();
      values.jobClosingDate = values.jobClosingDate.toString();
      // Map selected category name to category ID
      const selectedCategory = categoryOptions.find(
        (option) => option.label === values.jobCategory
      );
      values.jobCategory = selectedCategory ? selectedCategory.label : ''; // Store the category name
      // Map selected subcategories to an array of names
      const selectedSubcategoryNames = selectedSubCategories;

      values.subCategories = selectedSubcategoryNames;
      values.aboutJob = aboutJobContent;
      console.log('Updated Values:', values);
      // Send a PUT request to update the job listing
      const response = await axios.put(
        `${backendUrl}/api/jobs/admin/${jobId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Display success toast
        console.log(aboutJobContent);
        toast.success('Job listing updated successfully!');
        // Redirect to the job list page or any other appropriate page
        navigate('/admin/list-jobs');
      }
    } catch (error) {
      console.error('Job update error:', error);
      // Display error toast
      toast.error('An error occurred while updating the job listing.');
    }
  };

  // Fetch job categories
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    axios.get(`${backendUrl}/api/categories`).then((response) => {
      const categories = response.data.map((category) => ({
        label: category.name,
        value: category._id,
      }));
      setCategoryOptions(categories);
    });
  }, [backendUrl]);

  //function to generate seo url
  const generateSeoUrl = (category, title) => {
    if (category && title) {
      const categoryName = category.label.toLowerCase().replace(/\s+/g, '-');
      const jobTitle = title.toLowerCase().replace(/\s+/g, '-');
      return `${categoryName}_${jobTitle}`;
    }
    return '';
  };

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };
  
  const handleNavigation = (route) => {
    navigate(route);
  };

  // Define the validation schema for the form
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Job Title is required'),
    companyLogo: Yup.string().required('Company Logo URL is required'),
    companyName: Yup.string().required('Company Name is required'),
    qualification: Yup.string().required('Qualification is required'),
    location: Yup.string().required('Location is required'),
    experience: Yup.string().required('Experience is required'),
    extra: Yup.string().required('this is required'),
    salary: Yup.string().required('Salary is required'),
    role: Yup.string().required('Role is required'),
    jobCategory: Yup.string().required('Job Category is required'),
    seoUrl: Yup.string().required('SEO Url is required'),
    aboutJob: Yup.string()
      .required('About The Job is required')
  });

  return (
    <div className="container mt-3 mb-5">
      <div className="row">
        <div className="col-md-6">
          <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-moderators')}>
                  List Moderators
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-moderator')}>
                  Create Moderators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <div className="col-md-12">
          <div className="card joblist-card">
            <div className="card-header text-center joblist-form-header">
              <strong>Update Job Listing</strong>
            </div>
            <div className="card-body">
            
              {jobDetails.title ? ( // Check if jobDetails has been fetched
                <Formik
                  initialValues={{
                    ...jobDetails,
                    jobCategory: defaultCategory || '',
                    jobOpeningDate: jobDetails.jobOpeningDate,
                    jobClosingDate: jobDetails.jobClosingDate,
                    seoUrl: jobDetails.seoUrl,
                    subCategories: jobDetails.subCategories,
                    jobId: jobDetails.jobId,
                    aboutJob: jobDetails.aboutJob
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                       <div className="form-group joblist-form">
                <label htmlFor="title">Job Title</label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  className="form-control joblist-input"
                  // Generate and set the SEO URL based on category and title
                  onChange={(e) => {
                    setFieldValue('title', e.target.value);
                    const seoUrl = generateSeoUrl(values.jobCategory, e.target.value);
                    setFieldValue('seoUrl', seoUrl);
                  }}
                />
                <ErrorMessage name="title" component="div" className="error text-danger" />
              </div>

                <div className="form-group joblist-form">
                  <label htmlFor="companyLogo">Company Logo</label>
                  <Field type="text" id="companyLogo" name="companyLogo" className="form-control joblist-input" />
                  <ErrorMessage name="companyLogo" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="companyName">Company Name</label>
                  <Field type="text" id="companyName" name="companyName" className="form-control joblist-input" />
                  <ErrorMessage name="companyName" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="qualification">Qualification</label>
                  <Field type="text" id="qualification" name="qualification" className="form-control joblist-input" />
                  <ErrorMessage name="qualification" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="location">Location</label>
                  <Field type="text" id="location" name="location" className="form-control joblist-input" />
                  <ErrorMessage name="location" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="experience">Experience</label>
                  <Field type="text" id="experience" name="experience" className="form-control joblist-input" />
                  <ErrorMessage name="experience" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                <label htmlFor="extra">Link</label>
                <Field type="text" id="extra" name="extra" className="form-control joblist-input" />
                <ErrorMessage name="extra" component="div" className="error text-danger" />
              </div>

                <div className="form-group joblist-form">
                  <label htmlFor="salary">Salary</label>
                  <Field type="text" id="salary" name="salary" className="form-control joblist-input" />
                  <ErrorMessage name="salary" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="role">Role</label>
                  <Field type="text" id="role" name="role" className="form-control joblist-input" />
                  <ErrorMessage name="role" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="jobCategory">Job Category</label>
                  <Select
                    id="jobCategory"
                    name="jobCategory"
                    className='joblist-input'
                    options={categoryOptions}
                    onChange={(selectedOption) => {
                      setFieldValue('jobCategory', selectedOption ? selectedOption.label : ''); // Use setFieldValue here
                      const seoUrl = generateSeoUrl(selectedOption, values.title);
                    setFieldValue('seoUrl', seoUrl);
                    }}
                    value={categoryOptions.find((option) => option.label === values.jobCategory)} // Match by label
                  />
                  <ErrorMessage name="jobCategory" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="subCategories">Sub Categories</label>
                  <Select
                    id="subCategories"
                    name="subCategories"
                    className="joblist-input"
                    options={subCategoryOptions} // Options for subCategories
                    isMulti
                    value={selectedSubCategories.map((subcategory) => ({
                      label: subcategory,
                      value: subcategory,
                    }))} // Set the selected subCategories
                    onChange={(selectedOptions) => setSelectedSubCategories(selectedOptions.map((option) => option.label))}
                  />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="createdBy">Created By</label>
                  <Field
                    type="text"
                    id="createdBy"
                    name="createdBy"
                    className="form-control joblist-input"
                    value={jobDetails.createdBy} // Populate createdBy from jobDetails
                    readOnly
                  />
                </div>
                
                <div className="form-group joblist-form">
                  <label htmlFor="RefID">Ref ID</label>
                  <Field
                    type="text"
                    id="RefID"
                    name="RefID"
                    className="form-control joblist-input"
                  />
                </div>

                <div className="form-group joblist-form">
                <label htmlFor="seoUrl">SEO URL</label>
                <Field type="text" id="seoUrl" name="seoUrl" className="form-control joblist-input"/>
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="posts">Posts</label>
                  <Field type="text" id="posts" name="posts" className="form-control joblist-input" />
                  <ErrorMessage name="posts" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="jobOpeningDate">Job Publishing Date</label>
                  <Field
                    type="text" // Change type to "text"
                    id="jobOpeningDate"
                    name="jobOpeningDate"
                    className="form-control joblist-input"
                  />
                  <ErrorMessage name="jobOpeningDate" component="div" className="error text-danger" />
                </div>

                <div className="form-group joblist-form">
                  <label htmlFor="jobClosingDate">Job Closing Date</label>
                  <Field
                    type="text" // Change type to "text"
                    id="jobClosingDate"
                    name="jobClosingDate"
                    className="form-control joblist-input"
                  />
                  <ErrorMessage name="jobClosingDate" component="div" className="error text-danger" />
                </div>
                <div className="form-group joblist-form">
<label htmlFor="aboutJob">About Job</label>
<Editor // Use Editor from TinyMCE
apiKey="kfhdkybzno55pw816tl6zx44w1i6hqdt2rw3673lprn73qs9" // Replace with your TinyMCE API Key
initialValue={jobDetails.aboutJob}
                        init={{
                          height: 300,
                          menubar: 'favs file edit view insert format tools table help',
                          plugins: [
                            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                            'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                            'table', 'emoticons', 'help'
                          ],
                          toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                            'forecolor backcolor emoticons | help',
                          menu: {
                            favs: { title: 'Others', items: 'code visualaid | searchreplace | emoticons' }
                          },
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                        }}
                        onEditorChange={(content) => {
                          console.log(content)
                          setAboutJobContent(content);
                        }}
                      />
<ErrorMessage name="aboutJob" component="div" className="error text-danger" />
</div>
                      <div className="form-group joblist-form mt-4 text-center">
                        <button type="submit" className="btn btn-primary">
                          Update Listing
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <p>Loading...</p> // Display loading message while data is being fetched
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobUpdate;
