import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './CardCustomUser.css';
import { BiBuilding, BiCategory, BiCurrentLocation, BiFilterAlt } from "react-icons/bi";
import { HashLoader } from 'react-spinners';
import Footer from './Footer';
import Header from './Header';

const ListJobsUser = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const perPage = 9;

  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const numbers = [...Array(totalPages+1).keys()].slice(1)
  

  const [filterOptions, setFilterOptions] = useState({
    category: '',
    companyName: '',
    role: '',
    location: '',
  });

  const [categoryOptions, setCategoryOptions] = useState([]);

  const loadJobs = (page = 1) => {
    setLoading(true);
    const query = new URLSearchParams(filterOptions).toString();
    axios
      .get(`${backendUrl}/api/jobs/paginate?page=${page}&${query}`)
      .then((response) => {
        setJobs(response.data.jobs);
        setTotalPages(response.data.totalPages);
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
        setLoading(false);
      });
  };

  const loadAllJobsByCategory = () => {
    setLoading(true);
    const query = new URLSearchParams(filterOptions).toString();
    axios
      .get(`${backendUrl}/api/jobs?${query}`)
      .then((response) => {
        setJobs(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (filterOptions.category === '' || filterOptions.category === 'All') {
      loadJobs(); // Load initial jobs with pagination when the component mounts or "All" is selected
    } else {
      loadAllJobsByCategory(); // Load all jobs by category when a specific category is selected
    }
  }, [filterOptions]);

  useEffect(() => {
    axios.get(`${backendUrl}/api/categories`).then((response) => {
      const categories = response.data.map((category) => ({
        label: category.name,
        value: category.name,
      }));
      setCategoryOptions(categories);
    });
  }, [backendUrl]);

  const handleView = (seoUrl) => {
    navigate(`/jobs/${seoUrl}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterOptions({
      ...filterOptions,
      [name]: value,
    });
  };

  const filteredJobs = jobs.filter((job) => {
    const matchTitle = job.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchCategory = filterOptions.category
      ? job.jobCategory.toLowerCase() === filterOptions.category.toLowerCase()
      : true;
    const matchCompanyName = filterOptions.companyName
      ? job.companyName.toLowerCase().includes(filterOptions.companyName.toLowerCase())
      : true;
    const matchRole = filterOptions.role
      ? job.role.toLowerCase().includes(filterOptions.role.toLowerCase())
      : true;
    const matchLocation = filterOptions.location
      ? job.location.toLowerCase().includes(filterOptions.location.toLowerCase())
      : true;

    return matchTitle && matchCategory && matchCompanyName && matchRole && matchLocation;
  });

  function handlePage(e,number){
    loadJobs(number)
  }
  

  return (
    <>
    <Header/>
      <div className="container mt-3 user-home-main">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
            </div>
            <div className="col-12 col-md-8 col-sm-12">
              <div className='d-flex justify-content-end user-searchBar'>
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Search jobs"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="form-control text-center search-joblist-user d-inline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4 col-md-12 text-center">
          <div className="form-group row">
            <div className="col-md-6 filter-field">
              <BiFilterAlt />
              <label><strong>Find Job by Category:</strong></label>
              <select
                name="category"
                value={filterOptions.category}
                onChange={handleFilterChange}
                className="form-control filter-option"
              >
                <option value="">All</option>
                {categoryOptions.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 filter-field">
              <BiFilterAlt />
              <label><strong>Find Job by Company Name:</strong></label>
              <input
                type="text"
                name="companyName"
                value={filterOptions.companyName}
                onChange={handleFilterChange}
                className="form-control filter-option"
              />
            </div>
            <div className="col-md-6 filter-field">
              <BiFilterAlt />
              <label><strong>Find Job by Role:</strong></label>
              <input
                type="text"
                name="role"
                value={filterOptions.role}
                onChange={handleFilterChange}
                className="form-control filter-option"
              />
            </div>
            <div className="col-md-6 filter-field">
              <BiFilterAlt />
              <label><strong>Find Job by Location:</strong></label>
              <input
                type="text"
                name="location"
                value={filterOptions.location}
                onChange={handleFilterChange}
                className="form-control filter-option"
              />
            </div>
          </div>
        </div>

        <div className="job-cards mt-5">
          <div className="row">
            {loading ? (
              <div className="loader-container text-center">
                <HashLoader color="#198754" loading={loading} size={50} />
              </div>
            ) : (
               filteredJobs.map((job) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 mb-4"
                  key={job._id}
                  onClick={() => handleView(job.seoUrl)}
                >
                  <div className="card card-joblist-user">
                    <Link
                      to={`/jobs/${job.seoUrl}`}
                      target="_blank"
                      className='job-card-user-view'
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        if (event.ctrlKey || event.metaKey) {
                          return;
                        } else {
                          event.preventDefault();
                          handleView(job.seoUrl);
                        }
                      }}
                    >
                      <div className="card-header text-center card-header-joblist-user">
                        <strong>{job.title}</strong>
                      </div>

                      <div className="card-body d-flex">
                        <div className="flex-grow-1">
                          <p className="card-text"><BiBuilding /> <strong>Company:</strong> {job.companyName}</p>
                          <p className="card-text"><BiCurrentLocation /> <strong>Location:</strong> {job.location}</p>
                          <p className="card-text"><BiCategory /> <strong>Category:</strong> {job.jobCategory}</p>
                          <button className="btn btn-success me-2">
                            Explore The Job
                          </button>
                        </div>
                        <div className="ms-auto company-logo-card">
                          <img
                            src={job.companyLogo}
                            alt="company-logo"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {(filterOptions.category === '' || filterOptions.category === 'All') && (
          <div className="pagination-buttons text-center">
            {
              numbers.map((number,index)=>(
                <button onClick={(e)=>{handlePage(e,number)}} className={`btn btn-primary ms-1 ${currentPage === number ? "active" :''}`}>{number}</button>
              ))
            } 
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ListJobsUser;
