import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './cardcustom.css';
import { toast } from 'react-hot-toast';

const ListCategories = () => {
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_SERVER_URL;
  const token = localStorage.getItem('token'); // Get the JWT token
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/admin-login');
    } else {
      // Fetch the list of jobs from the server when the component mounts
      axios.get(`${backendUrl}/api/categories`).then((response) => {
        setCategories(response.data); // Assuming the response data is an array of category objects
      });
    }
  }, [navigate, token, backendUrl]);

  const handleLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
    navigate('/admin-login');
  };

  const handleUpdate = (categoryId) => {
    // Navigate to the UpdateCategory component with the categoryId as a parameter
    navigate(`/admin/update-category/${categoryId}`, { replace: true });
  };

  const handleDelete = (categoryId) => {
    // Ask for confirmation before deleting
    const confirmDelete = window.confirm('Are you sure you want to delete this category?');
    if (confirmDelete) {
      // Send a DELETE request to the server to delete
      axios
        .delete(`${backendUrl}/api/categories/admin/${categoryId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          // Remove the deleted category from the local state
          setCategories((prevCategories) => prevCategories.filter((category) => category._id !== categoryId));
          // Display success toast
          toast.success('Category deleted successfully');
        })
        .catch((error) => {
          console.error('Category deletion error:', error);
          // Display error toast
          toast.error('An error occurred while deleting the Category');
        });
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-4">
        <div className="admin-navigation">
            <div className="hamburger-menu">
              <div
                className={`menu-icon ${showOptions ? 'active' : ''}`}
                onClick={() => setShowOptions(!showOptions)}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
              <ul className={`options ${showOptions ? 'show' : ''}`}>
                <li className="nav-option-sec" onClick={() => handleNavigation('/')}>
                  Home
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-jobs')}>
                  List Jobs
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-job')}>
                  Create Job
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-categories')}>
                  List Categories
                </li>
                <li
                  className="nav-option-sec"
                  onClick={() => handleNavigation('/admin/create-category')}
                >
                  Create Category
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/list-moderators')}>
                  List Moderators
                </li>
                <li className="nav-option-sec" onClick={() => handleNavigation('/admin/create-moderator')}>
                  Create Moderators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search by Job Title"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control text-center search-joblist d-inline"
            />
          </div>
        </div>
        <div className="col-md-4 text-end">
          <button onClick={handleLogout} className="btn btn-joblist btn-primary">
            Logout
          </button>
        </div>
      </div>
      <div className="job-cards mt-5">
        <div className="row">
          {filteredCategories.map((category) => (
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4" key={category._id}>
              <div className="card card-joblist">
                <div className="card-header text-center card-header-joblist"><strong>{category.name}</strong></div>
                <div className="card-body">
                  <p className="card-title">About: {category.about}</p>
                  <div className="job-actions text-center">
                    <button onClick={() => handleUpdate(category._id)} className="btn btn-primary me-2">
                      Update
                    </button>
                    <button onClick={() => handleDelete(category._id)} className="btn btn-danger">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListCategories;