import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AdminLogin from './components/admin/AdminLogin';
import EditorHome from './components/admin/EditorHome';
import PrivateRoute from './components/admin/PrivateRoute';
import CreateJobListing from './components/admin/CreateJobListing';
import ListJobs from './components/admin/ListJobs';
import ListJobsByStaff from './components/admin/ListJobsByStaff';
import JobUpdate from './components/admin/JobUpdate';
import ViewJob from './components/admin/ViewJob';
import CreateCategory from './components/admin/CreateCategory';
import ListCategories from './components/admin/ListCategories';
import CreateSubCategory from './components/admin/CreateSubCategory';
import ListSubCategories from './components/admin/ListSubCategories';
import CategoryUpdate from './components/admin/CategoryUpdate';
import SubCategoryUpdate from './components/admin/SubCategoryUpdate';
import ListModerators from './components/admin/ListModerators';
import CreateModerator from './components/admin/CreateModerator';
import ModeratorUpdate from './components/admin/ModeratorUpdate';
// import NotFound from './components/NotFound';
import ListJobsUser from './components/user/ListJobsUser';
import ViewJobUser from './components/user/ViewJobUser';
import ListJobsByCategory from './components/user/ListJobsByCategory';
import TextEditor from './components/user/TextEditor';
import Privacy from './components/user/Privacy';
import About from './components/user/About';
import Contact from './components/user/Contact';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div className="App">
      <Router>
        <Toaster />
        <Routes>
          {/* Login routes */}
          <Route path="/admin-login" element={<AdminLogin setIsAuthenticated={setIsAuthenticated} />} />
          {/* Private routes for admin */}
          <Route path='/editorHome' element={<EditorHome isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              index // This ensures the PrivateRoute is active for this route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/create-job' element={<CreateJobListing isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              index // This ensures the PrivateRoute is active for this route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/list-jobs' element={<ListJobs isAuthenticated={isAuthenticated} role="admin" />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              index // This ensures the PrivateRoute is active for this route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/list-jobs-staff' element={<ListJobsByStaff isAuthenticated={isAuthenticated} role="admin" />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              index // This ensures the PrivateRoute is active for this route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/update-job/:jobId' element={<JobUpdate isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/view-job/:jobId' element={<ViewJob isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/create-category' element={<CreateCategory isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/list-categories' element={<ListCategories isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/update-category/:categoryId' element={<CategoryUpdate isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>

          <Route path='/admin/create-subCategory' element={<CreateSubCategory isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>
          <Route path='/admin/list-subCategories' element={<ListSubCategories isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>

          <Route path='/admin/update-subCategory/:subCategoryId' element={<SubCategoryUpdate isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>

          <Route path='/admin/list-moderators' element={<ListModerators isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>

          <Route path='/admin/create-moderator' element={<CreateModerator isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>

          <Route path='/admin/update-moderator/:moderatorId' element={<ModeratorUpdate isAuthenticated={isAuthenticated} />}>
            {/* Nest PrivateRoute under the corresponding Route */}
            <Route
              element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}
            />
          </Route>

          {/* Home page */}
          <Route path="/" element={<ListJobsUser />} />
          <Route path="/jobs/:seoUrl" element={<ViewJobUser />} />
          <Route path="/all-jobs/:jobCategory" element={<ListJobsByCategory />} />
          <Route path='/user/text-edit' element={<TextEditor />} />
          <Route path='/user/privacy' element={<Privacy />} />
          <Route path='/user/about' element={<About />} />
          <Route path='/user/contact' element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
