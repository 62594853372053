import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, isAuthenticated, role }) => {
  if (!isAuthenticated) {
    // Redirect to login and return null
    return <Navigate to="/admin-login" />;
  }

  const userRole = localStorage.getItem('role');

  if (role === userRole) {
    // Render the route if roles match
    return <Route element={element} />;
  }

  // Default to not rendering the route if the roles don't match
  return null;
};

export default PrivateRoute;
