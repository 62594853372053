import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './admin.css';
import axios from 'axios';
import toast from 'react-hot-toast';

const AdminLogin = ({setIsAuthenticated, setUsername }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_SERVER_URL;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await axios.post(`${backendUrl}/api/admin/login`, formData);
    if (response.status === 200) {
      toast.success(response.data.message);

      // Save the JWT token in localStorage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('role', 'admin');
      localStorage.setItem('username', response.data.username);

      const userRole = localStorage.getItem('role');
      const loggedInUsername = localStorage.getItem('username');

      // Set the username state if it's passed as a prop
        if (setUsername) {
          setUsername(loggedInUsername);
        }

      // Redirect to the homepage or any other route
      navigate('/admin/list-jobs');
    }
    } catch (error) {
    if (error.response) {
        // If there's a response from the server with an error status code (e.g., 401),
        // display the error message from it
        toast.error(error.response.data.error);
      } else {
        // If there's no response object, handle the error gracefully
        console.error(error); // Log the error for debugging purposes
        toast.error('An error occurred. Please try again later.');
      }
    }
  };
  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="row border rounded-5 p-3 bg-white shadow box-area">
        {/* Left Box */}
        <div className="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box" style={{ background: '#363945' }}>
          <div className="featured-image mb-3">
            <img src="./images/login.png" className="img-fluid" style={{ width: '250px' }} alt="Login" />
          </div>
          <p className="text-white fs-2" style={{ fontWeight: 600 }}>Be Verified</p>
          <small className="text-white text-wrap text-center" style={{ width: '17rem' }}>Login and Explore your admin powers!</small>
        </div>

        {/* Right Box */}
        <div className="col-md-6 right-box">
          <div className="row align-items-center">
            <div className="header-text mb-4">
              <h2>Hello, Admin</h2>
              <p>Please proceed to login for access.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="username" className="input-group mb-3">
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Username"
                  required
                />
              </Form.Group>
              <Form.Group controlId="password" className="input-group mb-1">
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
              </Form.Group>
              <div className="input-group mb-5 d-flex justify-content-between">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="formCheck" />
                  <label htmlFor="formCheck" className="form-check-label text-secondary"><small>Remember Me</small></label>
                </div>
              </div>
              <div className="input-group mb-3">
                <Button variant="danger" type="submit" className="btn btn-lg btn-danger w-100 fs-6">Login</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;